import React from 'react';
import { PageProps } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { useLocaleContext } from '../../../context/Locale';
import { formatCurrency, formatPercent } from '../../../shared/utils/formatNumbers';
import { depositRates, effectiveDate } from '../../../data/deposits/current-deposit-rates';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import Icon, { IconTypes } from '../../../components/Icon/Icon';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../data/common/common';
import { LocaleTag } from '../../../../config/locales';
import './style.scss';

type Props = PageProps & WithTranslation;

const CurrentDepositRates: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('currentDepositRates.seo.title'),
    },
    page: {
      title: t('currentDepositRates.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.MANGO_YELLOW,
        heroImage: {
          fileName: 'hero-deposits.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const longTerm = depositRates.filter((item) => item.term.type === 'months');
  const shortTerm = depositRates.filter((item) => item.term.type === 'days');

  const getLongTermRatesGICRows = () => {
    return ['maturity', 'annually', 'semiAnnually', 'monthly'].map((frequency) => (
      <tr key={frequency}>
        <td>{t(`currentDepositRates.${frequency}`)}</td>
        {longTerm.map((item, index) => (
          <td key={`${frequency}-${index}`}>
            {formatPercent((item as any)[frequency].value, activeLocaleTag)}
          </td>
        ))}
        <td key={`${frequency}-mindeposit`}>
          {formatCurrency(longTerm[0].minimumDeposit.value, activeLocaleTag)}
        </td>
      </tr>
    ));
  };

  const cdicBrochure = CommonData.websites.cdicBrochure[activeLocaleTag];

  return (
    <Layout options={layoutOptions} className="CurrentDepositRates">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <p className="CurrentDepositRates__generic-gap">
              {t('currentDepositRates.introParagraph')}
            </p>

            <div className="CurrentDepositRates__generic-gap">
              <h2 className="h4" id="long-term-gic">
                {t('currentDepositRates.longTermGIC')}
              </h2>
              <table className="CurrentDepositRates__generic-gap" aria-labelledby="long-term-gic">
                <thead>
                  <tr>
                    <th colSpan={7}>
                      <Trans
                        i18nKey="currentDepositRates.ratesNotice"
                        t={t}
                        values={{
                          effectiveDate: effectiveDate.toLocaleDateString(activeLocaleTag),
                        }}
                      />
                    </th>
                  </tr>
                  <tr className="payment-frequency">
                    <th>{t('currentDepositRates.paymentFrequency')}</th>
                    {longTerm.map((item, index) => (
                      <th key={`pflt-${index}`}>
                        {(item.term.value as number) / 12} {t('currentDepositRates.year')}
                      </th>
                    ))}
                    <th>{t('currentDepositRates.minimumDeposit')}</th>
                  </tr>
                </thead>
                <tbody>{getLongTermRatesGICRows()}</tbody>
              </table>
            </div>

            <div className="CurrentDepositRates__generic-gap">
              <h2 className="h4" id="short-term-gic">
                {t('currentDepositRates.shortTermGIC')}
              </h2>
              <table className="CurrentDepositRates__generic-gap" aria-labelledby="short-term-gic">
                <thead>
                  <tr>
                    <th colSpan={7}>
                      <Trans
                        i18nKey="currentDepositRates.ratesNotice"
                        t={t}
                        values={{
                          effectiveDate: effectiveDate.toLocaleDateString(activeLocaleTag),
                        }}
                      />
                    </th>
                  </tr>
                  <tr className="payment-frequency">
                    <th>{t('currentDepositRates.paymentFrequency')}</th>
                    {shortTerm.map((item, index) => (
                      <th key={`pflt-${index}`}>
                        {item.term.value} {t('currentDepositRates.days')}
                      </th>
                    ))}
                    <th>{t('currentDepositRates.minimumDeposit')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('currentDepositRates.maturity')}</td>
                    {shortTerm.map((item, index) => (
                      <td key={`st-${index}`}>
                        {formatPercent((item as any).maturity.value, activeLocaleTag)}
                      </td>
                    ))}
                    <td key="st-mindeposit">
                      {formatCurrency(shortTerm[0].minimumDeposit.value, activeLocaleTag)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="Deposits__generic-gap">
              <h2 className="h4">{t('deposits.cdicBlock.heading')}</h2>
              <p>
                <Trans t={t} i18nKey="deposits.cdicBlock.body">
                  <ExternalLink href={cdicBrochure} />
                </Trans>
              </p>
              <div className="deposits-cdic-logo">
                <ExternalLink href={CommonData.websites.cdic[activeLocaleTag]}>
                  <div className="cdic-logo-wrapper">
                    <Icon
                      type={activeLocaleTag === LocaleTag.EN ? IconTypes.CDIC : IconTypes.SADC}
                    />
                  </div>
                </ExternalLink>
              </div>
            </div>
          </section>
          <aside className="column column-33">
            <div className="Deposits__generic-gap Deposits__page-menu">
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.DEPOSITS)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
              >
                {t('deposits.buttonLabelDeposits')}
              </ButtonLink>
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.DEPOSITS.FAQ)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
              >
                FAQ
              </ButtonLink>
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.DEPOSITS)(CurrentDepositRates);
